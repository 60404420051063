html {
  height: auto
}
* {box-sizing: border-box;}
body {
  height: auto;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* Avenir Fonts */

@font-face {
  font-family: 'AvenirLTStd-Heavy';
  src: url('./assets/fonts/AvenirHeavy/AvenirLTStd-Heavy.eot');
  src: local('Avenir LT Std Heavy'), local('AvenirLTStd-Heavy'),
      url('./assets/fonts/AvenirHeavy/AvenirLTStd-Heavy.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/AvenirHeavy/AvenirLTStd-Heavy.woff2') format('woff2'),
      url('./assets/fonts/AvenirHeavy/AvenirLTStd-Heavy.woff') format('woff'),
      url('./assets/fonts/AvenirHeavy/AvenirLTStd-Heavy.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AvenirLTStd-Roman';
  src: url('./assets/fonts/AvenirRoman/AvenirLTStd-Roman.eot');
  src: local('Avenir LT Std Heavy'), local('AvenirLTStd-Heavy'),
      url('./assets/fonts/AvenirRoman/AvenirLTStd-Roman.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/AvenirRoman/AvenirLTStd-Roman.woff2') format('woff2'),
      url('./assets/fonts/AvenirRoman/AvenirLTStd-Roman.woff') format('woff'),
      url('./assets/fonts/AvenirRoman/AvenirLTStd-Roman.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AvenirLTStd-Black';
  src: url('./assets/fonts/AvenirBlack/AvenirLTStd-Black.eot');
  src: local('Avenir LT Std Black'), local('AvenirLTStd-Black'),
      url('./assets/fonts/AvenirBlack/AvenirLTStd-Black.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/AvenirBlack/AvenirLTStd-Black.woff2') format('woff2'),
      url('./assets/fonts/AvenirBlack/AvenirLTStd-Black.woff') format('woff'),
      url('./assets/fonts/AvenirBlack/AvenirLTStd-Black.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AvenirLTStd-Medium';
  src: url('./assets/fonts/AvenirMedium/AvenirLTStd-Medium.eot');
  src: local('Avenir LT Std Black'), local('AvenirLTStd-Black'),
      url('./assets/fonts/AvenirMedium/AvenirLTStd-Medium.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/AvenirMedium/AvenirLTStd-Medium.woff2') format('woff2'),
      url('./assets/fonts/AvenirMedium/AvenirLTStd-Medium.woff') format('woff'),
      url('./assets/fonts/AvenirMedium/AvenirLTStd-Medium.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AvenirLTStd-Book';
  src: url('./assets/fonts/AvenirBook/AvenirLTStd-Book.eot');
  src: local('Avenir LT Std Book'), local('AvenirLTStd-book'),
      url('./assets/fonts/AvenirBook/AvenirLTStd-Book.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/AvenirBook/AvenirLTStd-Book.woff2') format('woff2'),
      url('./assets/fonts/AvenirBook/AvenirLTStd-Book.woff') format('woff'),
      url('./assets/fonts/AvenirBook/AvenirLTStd-Book.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
}

a[target="_blank"] {
  background: url("./assets/icons/external_link_icon.svg") no-repeat right center;
  background-size: 14px; 
  padding-right: 18px;
}

.active-item {
  animation: glow 2s ease-in;
  /* animation-iteration-count: 1 */
}

@keyframes glow {
  from {
    box-shadow: 0 0 10px -10px #d1f1ff;
  }
  to {
    box-shadow: 0 0 10px 6px #57cbfd;
  }
}